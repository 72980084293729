import { template as template_3221c5a1605b45c69a7bbc4cfe6bc207 } from "@ember/template-compiler";
const EmptyState = template_3221c5a1605b45c69a7bbc4cfe6bc207(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
