import { template as template_89ad556d978f4d878f7db668557becab } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_89ad556d978f4d878f7db668557becab(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
