import { template as template_cca40c4876c84f8da9052ce25871669a } from "@ember/template-compiler";
const SidebarSectionMessage = template_cca40c4876c84f8da9052ce25871669a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
