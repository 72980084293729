import { template as template_e118f40654704107a38b27e900fcbfc4 } from "@ember/template-compiler";
const FKLabel = template_e118f40654704107a38b27e900fcbfc4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
